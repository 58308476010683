import NavItem from "./NavItem";


export default [
  new NavItem(
    'claim',
    'Claims',
    '/claim'
  ),
  new NavItem(
    'report',
    'Reports',
    '/report',
  ),
  new NavItem(
    'client',
    'Clients',
    '/client'
  ),
  new NavItem(
    'workflow',
    'Workflow',
    '/workflow',
  ),
];
