import { Pipe, PipeTransform } from "@angular/core";

const PADDING = "000000";

export declare type fractionalParts = [
  boolean, // true = positive, false = negative
  string, // int part
  string // fraction
];

@Pipe({ name: "charges" })
export class ChargesPipe implements PipeTransform {

  private DECIMAL_SEPARATOR: string;
  private THOUSANDS_SEPARATOR: string;

  constructor() {
    // TODO comes from configuration settings
    this.DECIMAL_SEPARATOR = ".";
    this.THOUSANDS_SEPARATOR = "'";
  }

  transform(value: number | string, fractionSize: number = 2): string {
    let [isPositive, integer, fraction ] = this.getFractionParts(value);

    return `${isPositive ? '' : '-'}$${integer}.${fraction}`;
  }

  parse(value: string, fractionSize: number = 2): string {
    let [isPositive, integer, fraction ] = this.getFractionParts(value);

    const strippedValue = `${isPositive ? '' : '-'}${integer}.${fraction}`;
    return strippedValue;
  }

  private getFractionParts(numberString: string | number): fractionalParts {
    let isPositive: boolean, intPart: string, fractionPart: string;

    numberString = (numberString && numberString+'') || '';
    if(numberString.length === 0){
      return [false, '0', '00'];
    }
    [intPart, fractionPart] = numberString.split(this.DECIMAL_SEPARATOR);
    isPositive = intPart[0] !== '-';
    intPart = this.stripNonInt(intPart) || '0';
    fractionPart = this.stripNonInt(fractionPart) || '00';
    
    return [ isPositive, intPart, fractionPart ];
  }

  stripNonInt(value: string) {
    if(typeof value !== 'string'){
      return undefined;
    }
    return value.replace(/\D/g,'');
  }

}
