import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import {
  MatButtonModule,
  MatDialogModule,
  MatInputModule,
  MatTableModule,
  MatFormFieldModule,
  MatPaginatorModule,
  MatSortModule,
  MatIconModule,
  MatSelectModule,
  MatTabsModule,
  MatToolbarModule,
  MatCardModule,
  MatRadioModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatListModule,
  MatDividerModule,
  MatSnackBarModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatMenuModule,
  MatExpansionModule,
  MatSidenavModule,
  MatAutocompleteModule
} from '@angular/material';
import { PasswordDialogComponent } from './password-dialog/password-dialog.component';
import { CoreModule } from '../core/core.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEdit, faCoffee, faTrashAlt, faArrowAltCircleDown, faClone, faCheck, faTimes, faFilePdf, faPaperclip, faMinus, faTimesCircle, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { SnackBarService } from './snackbar.service';
import { SsnPipe } from './ssn.pipe';
import {TextMaskModule} from 'angular2-text-mask';
import { ChargesPipe } from './charges.pipe';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';

const icons = [
  faEdit,
  faCoffee,
  faTrashAlt,
  faArrowAltCircleDown,
  faCheck,
   faTimes,
   faFilePdf,
   faPaperclip,
   faMinus,
   faClone,
   faTimesCircle,
   faEllipsisV
];

icons.forEach(icon=>library.add(icon));

const modules = [
  CoreModule,
  FontAwesomeModule,
  CommonModule,
  FormsModule,
  MatButtonModule,
  MatDialogModule,
  MatInputModule,
  MatTableModule,
  MatFormFieldModule,
  MatPaginatorModule,
  MatSortModule,
  MatIconModule,
  MatSelectModule,
  MatTabsModule,
  MatToolbarModule,
  ReactiveFormsModule,
  MatCardModule,
  MatRadioModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatListModule,
  MatDividerModule,
  MatSnackBarModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatMenuModule,
  MatExpansionModule,
  MatSidenavModule,
  MatAutocompleteModule,
  TextMaskModule,
];

@NgModule({
  imports: [
    ...modules,
  ],
  exports: [
    ...modules,
    SsnPipe

  ],
  declarations: [PasswordDialogComponent, ConfirmationDialogComponent, SsnPipe, AlertDialogComponent],
  entryComponents: [PasswordDialogComponent, ConfirmationDialogComponent, AlertDialogComponent],
  providers: [SnackBarService, ChargesPipe, SsnPipe]
})
export class SharedModule {}
