import { Injectable } from '@angular/core';
import NavItems from './NavItems';

/**
   Defined as a service since we expect sub-navigation, in which case this service can be passed
   new values to populate sub-nav items as needed from within respective pages
 */
@Injectable({
  providedIn: 'root'
})
export class NavService {

  constructor() {}

  public getNavItems(){
    return NavItems;
  }
}
