import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  MatDialog,
  MatDialogRef,
  MatDialogConfig
} from '@angular/material';
import { PasswordDialogComponent } from './../shared/password-dialog/password-dialog.component';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { ComponentType } from '@angular/cdk/portal';
import { Auth } from 'src/app/core/auth';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  passwordDialog: MatDialogRef<PasswordDialogComponent>;
  confirmationDialog: MatDialogRef<ConfirmationDialogComponent>;
  component: ComponentType<any>;
  confirmationMessage: string;
  tokenHeaders = new HttpHeaders({
    'Authorization': 'Bearer ' + localStorage.getItem("auth-token"),
    "Content-Type": "application/json"
  });

  constructor(
    private httpClient: HttpClient,
    private dialog: MatDialog,
    private route: Router
  ) { }


  openPasswordDialog(route: string) {
    const config = {
      data: route
    };

    this.passwordDialog = this.dialog.open(PasswordDialogComponent, config);
  }

  setDialogComponent(component: ComponentType<any>) {
    this.component = component;
  }

  setConfirmationMessage(confirmationMessage: string) {
    this.confirmationMessage = confirmationMessage;
  }

  getDialogComponent() {
    return this.component;
  }

  setToken(response: Auth) {
    localStorage.setItem("auth-token", response.token);
  }

  authenticate(password: string) {
    const credentials = { 'password': password };
    return this.httpClient.post(`${environment.apiUrl}/api/Users/token`, credentials, {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    });
  }

  isAuthenticated() {
    return this.httpClient.get(`${environment.apiUrl}/api/Users/ValidToken`, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem("auth-token"),
        "Content-Type": "application/json"
      })
    });
  }

  authenticated(route: string, component: ComponentType<any> = null, config: MatDialogConfig = null) {
    this.httpClient.get(`${environment.apiUrl}/api/Users/ValidToken`, {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem("auth-token"),
        "Content-Type": "application/json"
      })
    }).subscribe(() => {
      if(route) {
        this.route.navigateByUrl(route);
      } else {
        const confirmationConfig = {
          data: this.confirmationMessage,
          disableClose: true,
        };
        this.confirmationDialog = this.dialog.open(ConfirmationDialogComponent, confirmationConfig);
        this.confirmationDialog.afterClosed().subscribe(result => {
          if(!result) {
            this.dialog.open(component, config);
          }
        });
      }
    }, () => {
      this.openPasswordDialog(route);
    });
  }
}
