import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

  confirmationMessage = "";
  constructor(
    private confirmationDialog: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: string) { }

  ngOnInit() {
    this.confirmationMessage = this.data;
  }

  cancel() {
    this.confirmationDialog.close('cancel');
  }

  onSubmit() {
    this.confirmationDialog.close();
  }

}
