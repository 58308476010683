import { Component, ViewEncapsulation } from '@angular/core';

// import pencilTemplate from '../assets/baseline-edit-24px.svg';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
  title = 'employer-advocates';
}
