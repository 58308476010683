import { Component, OnInit } from '@angular/core';
import { NavService } from './nav.service';
import NavItem from './NavItem';
import { Router, NavigationStart } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

  navItems: NavItem[];
  activeUrl: string;
  environment

  constructor(
    private router: Router,
    private navService: NavService,
  ) {
    this.environment = environment
  }

  ngOnInit() {
    this.navItems = this.navService.getNavItems();
    this.router.events.subscribe(event => {
      if(event instanceof NavigationStart){
        this.activeUrl = event.url;
      }
    });
  }

  isActive() {

  }

  navigate(navItem: NavItem){
    if(this.activeUrl.includes(navItem.value)) {

    }
    this.router.navigate([navItem.route]);
  }

}
