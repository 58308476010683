import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ClaimListComponent } from './claim/claim-list/claim-list.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'claim',
    pathMatch: 'full'
  },
  {
    path: 'claim',
    loadChildren: './claim/claim.module#ClaimModule',
  },
  {
    path: 'report',
    loadChildren: './report/report.module#ReportModule',
  },
  {
    path: 'client',
    loadChildren: './client/client.module#ClientModule',
  },
  {
    path: 'workflow',
    loadChildren: './workflow/workflow.module#WorkflowModule',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
