import { Component, OnInit, Inject } from '@angular/core';
import { MatFormField, MatButton, MatInput, MAT_DIALOG_DATA, MatHint } from '@angular/material';
import { FormControl } from '@angular/forms';
import { AuthService } from 'src/app/core/auth.service';
import { MatDialogRef, MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { Auth } from 'src/app/core/auth';

@Component({
  selector: 'app-password-dialog',
  templateUrl: './password-dialog.component.html',
  styleUrls: ['./password-dialog.component.scss']
})
export class PasswordDialogComponent implements OnInit {
  showErrorMessage = false;
  passwordControl: FormControl = new FormControl('')
  constructor(
    private dialog: MatDialog,
    private authService: AuthService,
    private passwordDialog: MatDialogRef<PasswordDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string,
    private router: Router) { }

  ngOnInit() {
  }

  cancel() {
    this.passwordDialog.close('cancel');
  }

  onSubmit() {
    this.authService.authenticate(this.passwordControl.value)
      .subscribe(response => {
        this.authService.setToken(new Auth(response));
        if (this.data) this.router.navigate([this.data]);
        else if (this.authService.getDialogComponent()) this.dialog.open(this.authService.getDialogComponent());
        this.passwordDialog.close('passwordSet');
      },() => {
        this.passwordControl.setErrors({ 'incorrect': true });
        this.showErrorMessage = true;
      });
  }

}
