import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ssnFormatter'
})
export class SsnPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let transformedValue;
    if (value && value.length > 6) {
      transformedValue = this.insert(value, '-', 3);
      transformedValue = this.insert(transformedValue, '-', 6);
      return transformedValue;
    }

    return value;

  }

  insert(oldString, insertValue, pos) {
    if(typeof(pos) == "undefined") {
     pos = 0;
   }
    if(typeof(insertValue) == "undefined") {
      insertValue = '';
   }
    return oldString.slice(0, pos) + insertValue + oldString.slice(pos);
     }

}
